export default {
	name: "ClickonceApplications",

	data() {
		return {
			FileSync: {
				Version: null,
				URL: null
			},
			SpecializedFileUploader: {
				Version: null,
				URL: null
			},
			FileProcessingConsole: {
				Version: null,
				URL: null
			}
		};
	},

	methods: {
	},

	async mounted() 
	{
		var baseHttpStClickonce = `${Settings.StaticURL}/Clickonce/`;

		this.FileSync.Version = await GD_API.GetParameterValue("ESF.DocMan.FileSync.Version");
				
		this.FileProcessingConsole.Version = await GD_API.GetParameterValue("ESF.FileProcessing.Console.Version");

		this.SpecializedFileUploader.Version = await GD_API.GetParameterValue("ESF.DocMan.SpecializedFileUploader.Version");
				

		if (Settings.EnvironmentIsProduction)
		{
			this.FileProcessingConsole.URL = `${baseHttpStClickonce}DocMan/FileProcessingConsole/ESF.FileProcessing.Console.application`;

			this.SpecializedFileUploader.URL = `${baseHttpStClickonce}DocMan/SpecializedFileUploader/ESF.DocMan.SpecializedFileUploader.application`;

			this.FileSync.URL = `${baseHttpStClickonce}DocMan/FileSync/ESF.DocMan.FileSync.application`;
		}
		else
		{
			this.FileProcessingConsole.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/FileProcessingConsole/ESF.FileProcessing.Console.application`;

			this.SpecializedFileUploader.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/SpecializedFileUploader/ESF.DocMan.SpecializedFileUploader.application`;

			this.FileSync.URL = `${baseHttpStClickonce}${Settings.EnvironmentName}/DocMan/FileSync/ESF.DocMan.FileSync.application`;
		}
	}

};